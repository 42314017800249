import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { FaLinkedin, FaEnvelope, FaGithub } from "react-icons/fa"
import { TimelineContent } from "./cv/timelineGrid"

export const serifHeader = css`
    font-family: "Lora", serif;
    font-weight: 400;
`;

const ResumeTitle = styled.h1`
    transition: opacity 0.5s ease;
    ${serifHeader}
    font-size: 4rem;
    text-align: left;
    margin: 7rem 0 0 0;
    padding-bottom: 0;

    @media screen and (max-width: 900px) {
        font-size: 2em;
    }

    @media print {
        font-size: 2.8em;
        margin-top: 3rem;
        opacity: 1;
        padding-bottom: 0;
    }
`;

const ResumeSubtitle = styled.h2`
    opacity: ${props => props.fadeOut ? `0` : `1` };
    transition: opacity 0.5s ease;
    ${serifHeader}
    font-size: 1.6rem;
    margin: 1rem 0 1rem 0;
    text-align: left;
    // border-top: 1px solid rgba(0, 0, 0, 0.5);

    @media screen and (max-width: 900px) {
        font-size: 1.2rem;
    }

    @media print {
        font-size: 1.1em;
        margin: 1rem 0 0.5rem 0;
        opacity: 1;
        border-top: 0;
        max-width: 100%;
    }
`;

const ResumeSocialList = styled.ul`
    transition: opacity 0.5s ease;
    list-style-type: none;
    margin: 0;
    display: flex;
    padding-left: 0;

    li {
        margin-bottom: 0;
    }

    li:last-child a {
        border-right: 0;
    }

    a, span {
        margin: 0.5rem 2rem 0.5rem 0.2rem;
        text-decoration: none;
        ${serifHeader}
        font-size: 1.2rem;
        color: #333;
        border-bottom: 1px solid #ddd;

        @media screen and (max-width: 900px) {
            border-right: 0;
        }

        &::after {
            content: '';
        }

        @media print {
            font-size: 0.8em;
        }
    }

    li:hover {
        a {
            color: #000;
            border-bottom-color: #aaa;
        }

        svg {
            color: #000;
        }

    }

    svg {
        color: #333;
        position: relative;
        top: 0.1rem;

        @media print {
            top: 0.05rem;
        }
    }

    @media screen and (max-width: 900px) {
        display: block;
        margin-top: 0;
    }

    @media print {
        opacity: 1;
        font-size: 1rem;
    }
`;

export const ResumeSocial = props => (
    <ResumeSocialList>
        {/* <li css={showInPrint}><GlassButton href="https://chris.chamberlain.id.au"><FaGlobeAsia />chris.chamberlain.id.au</GlassButton></li> */}
        <li><FaLinkedin /> <a href="https://www.linkedin.com/in/chris-j-chamberlain" target="_blank" rel="noopener noreferrer">linkedin.com/in/chris-j-chamberlain</a></li>
        <li><FaGithub /> <a href="https://github.com/neon64" target="_blank" rel="noopener noreferrer">github.com/neon64</a></li>
        <li><FaEnvelope /> <a href="mailto:chris@chamberlain.id.au">chris@chamberlain.id.au</a></li>
    </ResumeSocialList>
)

export const Banner = props => (
    <TimelineContent>

        <ResumeTitle fadeOut={props.bannerFadeOut}>Chris Chamberlain</ResumeTitle>
        <ResumeSubtitle fadeOut={props.bannerFadeOut}>Bachelor of Science Student - Computing and Mathematics at the University of Melbourne</ResumeSubtitle>

        <ResumeSocial />

    </TimelineContent>
);