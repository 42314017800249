import styled from '@emotion/styled'
import { css } from '@emotion/core'
import React from 'react'

const Left = styled.div`
    display: flex;
    justify-content: flex-end;
    background: #eee;
    flex: 1 0 15em;

    @media screen and (max-width: 600px) {
        display: none;
    }

    @media print {
        background: none;
        flex: 0 auto;
        width: 8rem;
        display: ${ props => props.noIndentInPrint ? 'none' : 'flex' };
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
`;

const Right = styled.div`
    flex: 1 0 10em;

    @media print {
        display: none;
    }

    @media screen and (max-width: 800px) {
        display: none;
    }
`;

const Middle = styled.div`
    max-width: 1024px;
    width: 90%;
    position: relative;
    border-left: 2px solid #ccc;
    padding: 0 2em;
    background-image: #fff;

    @media screen and (max-width: 600px) {
        border-left-width: 0;
    }

    @media print {
        background: none;
        border-left-style: dashed;
        border-left-width: ${ props => props.noIndentInPrint ? '0' : '0.5px' };
        padding: ${ props => props.noIndentInPrint ? '0' : '0 2em' };
    }
`;

const container = css`
    margin: 0;
    display: flex;
    align-items: stretch;
    @media print {
        page-break-inside: avoid;
    }

    @media screen and (max-width: 600px) {
        flex-direction: column;
        margin-left: 2em;
        border-left: 1px dashed #0c487e;
        background-color: #fff;
    }
`;

const contentMiddle = css`
    padding-top: 2rem;

    @media print {
        padding-top: 0;
    }
`;

const TimelineContent = ({children}) => {
    return (
        <div css={container}>
            <Left noIndentInPrint={true}></Left>
            <Middle css={contentMiddle} noIndentInPrint={true}>
                {children}
            </Middle>
            <Right></Right>
        </div>
    );
}

const spacer = css`
    margin: 4rem 0;

    @media print {
        margin: 0;
    }
`;

const TimelineSpacer = () => {
    return (
        <TimelineContent>
            <div css={spacer}></div>
        </TimelineContent>
    )
}

export { Left, Middle, Right, container, TimelineContent, TimelineSpacer };