import React from "react"
import styled from '@emotion/styled'
import { container, Left, Middle, Right } from './timelineGrid';

const Logo = styled.div`
    width: 4em;
    height: 4em;
    margin: 0 1.5em 0.5em 0.5em;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 600px) {
        margin: 0 0.5em 0.5em 2em;
    }

    @media print {
        width: 3em;
        height: 3em;
        margin: 0 0.5rem 0.5rem 0.5rem;
    }
`;

const LogoImg = styled.img`
    max-width: 100%;
    max-height: 100%;
    mix-blend-mode: multiply;
`;

const Date = styled.span`
    text-align: right;
    margin: 1.2rem 1rem 1rem 1rem;

    @media screen and (max-width: 600px) {
        margin: 0.5rem 0 0 2rem;
        text-align: left;
    }

    @media print {
        text-align: center;
        margin: 1.2rem 0.5rem 0.5rem 0.5rem;
    }
`;

/* awesome 'timeline dots' based upon this demo: https://codepen.io/alanhouser/pen/aErrQJ */

const Dot = styled.span`
    width: 0.9em;
    height: 0.9em;
    border-radius: 50%;
    border: 0.2em solid #0c487e;
    background-color: #fff;
    position: absolute;
    top: 1.75em;
    left: -0.5em;

    @media print {
        top: 1.6em;
    }
`;

const DescriptionInner = styled.div`
    border-bottom: ${props => props.showBottomBorder ? `1px solid #ccc` : `0` };
    padding: 1em 0;

    strong {
        color: #0c487e;
    }

    @media print {
        padding: 1em 0;
    }

    @media screen and (max-width: 600px) {
        padding: ${props => props.showBottomBorder ? `1em 0 2em 0` : `1em 0` };
    }
`;

const Container = styled.li`
    &:last-child ${DescriptionInner} {
        border-bottom: 0;
    }
`;



const TimelineItem = ({ date, children, logo, showDot, showBottomBorder, noIndentInPrint }) => {
    if(showDot == null) {
        showDot = true;
    }
    if(showBottomBorder == null) {
        showBottomBorder = true;
    }
    if(noIndentInPrint == null) {
        noIndentInPrint = false;
    }
    if(logo) {
        var logoHtml = (
            <Logo noIndentInPrint={noIndentInPrint}>
                    <LogoImg src={ logo.src } alt={ logo.alt } style={logo.style}/>
            </Logo>
        );
    } else {
        logoHtml = (<Logo noIndentInPrint={noIndentInPrint}></Logo>);
    }
    if(showDot) {
        var dot = (<Dot />);
    } else {
        dot = (<></>);
    }
    return (
        <Container css={container}>
            <Left noIndentInPrint={noIndentInPrint}>
                <Date noIndentInPrint={noIndentInPrint}>{ date }</Date>
                { logoHtml }
            </Left>
            <Middle noIndentInPrint={noIndentInPrint}>
                { dot }
                <DescriptionInner showBottomBorder={showBottomBorder} noIndentInPrint={noIndentInPrint}>
                    { children }
                </DescriptionInner>
            </Middle>
            <Right>

            </Right>
        </Container>
    )
}

export default TimelineItem