import styled from '@emotion/styled'
import React from 'react'
import TimelineItem from './timelineItem';

const Timeline = styled.ul`
    list-style-type: none;
    margin: 0 auto;
    z-index: 2;

    @media screen and (max-width: 600px) {
        width: 100%;
    }

    @media print {
        page-break-inside: avoid;
        margin: 0;
        width: auto;
        max-width: 100%;
    }
`;

const Title = styled.h1`
    display: inline-block;
    text-align: left;
    margin-top: 1rem;
    padding-bottom: 0.2em;
    font-size: 2em;
    border-bottom: 0.05em solid #999;

    @media print {
        display: block;
        margin: 0;
        padding: 0 0 0.5em 0;
        border-bottom: 0.01em solid #999;
        font-size: 1.3em;
    }
`;

export default ({ title, children }) => (
    // data-sal="zoom-in"
    <Timeline data-sal-duration="1000">
        <TimelineItem showDot={false} showBottomBorder={false} noIndentInPrint={true}>
            <Title>{ title }</Title>
        </TimelineItem>
        { children }
    </Timeline>
)